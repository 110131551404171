<template>
  <div>
    <b-row>
      <b-col cols="12" md="4">
        <router-link to="/input-suara/calon/2" class="text-decoration-none">
          <b-card class="mb-2">
            <b-card-text class="text-center">
              <img
                src="/assets/images/logo-dpr.jpeg"
                height="200"
                alt="Logo DPR"
              />
              <p class="my-3 h4">DPR</p>
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
      <b-col cols="12" md="4">
        <router-link to="/input-suara/calon/2" class="text-decoration-none">
          <b-card class="mb-2">
            <b-card-text class="text-center">
              <img
                src="/assets/images/logo-dprd.png"
                height="200"
                alt="Logo DPR"
              />
              <p class="my-3 h4">DPRD TK I</p>
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
      <b-col cols="12" md="4">
        <router-link to="/input-suara/calon/3" class="text-decoration-none">
          <b-card class="mb-2">
            <b-card-text class="text-center">
              <img
                src="/assets/images/logo-dprd.png"
                height="200"
                alt="Logo DPR"
              />
              <p class="my-3 h4">DPRD TK II</p>
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: {},
      model: {},
      list: {},
    };
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>